#survey-result {
  min-width: 1200px;
  margin: 40px auto; }
  #survey-result .chart-box {
    margin-bottom: 60px;
    background-color: #fff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.15);
    padding: 0 40px 90px 40px;
    position: relative; }
    #survey-result .chart-box .title {
      font-size: 1.3em;
      padding: 40px 0 20px 0;
      text-align: center;
      color: #8c8c8c;
      height: 94px;
      box-sizing: border-box; }
    #survey-result .chart-box.vas .title .up {
      color: #7CEA45; }
    #survey-result .chart-box.vas .title .down {
      color: #FD1310; }
    #survey-result .chart-box.p-scale .title .down {
      color: #7CEA45; }
    #survey-result .chart-box.p-scale .title .up {
      color: #FD1310; }
    #survey-result .chart-box.p-scale:before {
      content: " ";
      position: absolute;
      left: 87px;
      top: 110px;
      height: 400px;
      width: 45px;
      background: url(../images/pscale.png); }
    #survey-result .chart-box .many, #survey-result .chart-box .few {
      position: absolute;
      top: 65px;
      left: 60px;
      width: 100px;
      font-size: 1em;
      color: #B1CDCD;
      text-align: center; }
      #survey-result .chart-box .many .scale, #survey-result .chart-box .few .scale {
        position: absolute;
        right: 0px;
        bottom: -28px; }
    #survey-result .chart-box .few {
      top: 520px; }
      #survey-result .chart-box .few .scale {
        bottom: 44px; }
    #survey-result .chart-box.vas .few {
      left: 33px; }
    #survey-result .chart-box.vas .many {
      top: 40px;
      left: 33px; }
  #survey-result .c-chart {
    height: 450px; }
  #survey-result .ct-series-a .ct-area {
    fill: #48BABC; }
  #survey-result .ct-series-a .ct-point {
    stroke: #48BABC; }
  #survey-result .ct-series-a .ct-line {
    stroke: #B2D3DB; }
  #survey-result .ct-label {
    color: #48BABC; }
