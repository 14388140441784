.post-get-more {
  display: flex;
  justify-content: center; }
  .post-get-more button {
    padding: 10px;
    background-color: #f7f7f7;
    text-align: center;
    font-size: 0.7em;
    text-transform: uppercase;
    color: #333;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent; }
