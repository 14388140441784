li.post {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.15); }
  li.post img {
    max-width: 50px;
    max-height: 50px; }
