#survey-answer .invalid-msg {
  font-size: 1.2em;
  padding-bottom: 40px;
  text-align: center;
  color: red; }

#survey-answer .send-options {
  margin: 40px 0; }
  #survey-answer .send-options > .send {
    position: relative;
    background: #48BABC;
    -webkit-box-shadow: 0px 2px 2px 0px #388E8F;
    -moz-box-shadow: 0px 2px 2px 0px #388E8F;
    box-shadow: 0px 2px 2px 0px #388E8F;
    padding: 15px 30px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.5em;
    text-align: center;
    margin: auto;
    width: 300px; }

#survey-answer .answered {
  margin-bottom: 20px;
  background-color: #ecf5f5;
  color: #8c8c8c;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  font-size: 1.5em; }
  #survey-answer .answered .result {
    position: relative;
    background: #48BABC;
    -webkit-box-shadow: 0px 2px 2px 0px #388E8F;
    -moz-box-shadow: 0px 2px 2px 0px #388E8F;
    box-shadow: 0px 2px 2px 0px #388E8F;
    padding: 15px 30px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    text-align: center;
    margin: 40px auto;
    width: 200px; }
