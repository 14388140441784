.vas-scale {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  overflow-y: scroll; }
  .vas-scale .instruction {
    position: absolute;
    top: 65px;
    left: 60px;
    font-size: 0.7em; }
  .vas-scale .set {
    position: relative;
    background: #48BABC;
    -webkit-box-shadow: 0px 2px 2px 0px #388E8F;
    -moz-box-shadow: 0px 2px 2px 0px #388E8F;
    box-shadow: 0px 2px 2px 0px #388E8F;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.7em;
    margin: auto;
    width: 200px;
    text-align: center; }
  .vas-scale .bar {
    position: relative;
    width: 137px;
    height: 400px;
    margin: 185px auto;
    margin-bottom: 100px;
    cursor: pointer; }
    .vas-scale .bar .best {
      position: absolute;
      top: -75px;
      left: 0;
      width: 140px;
      line-height: 1.1em;
      font-size: 0.6em;
      text-align: center; }
    .vas-scale .bar .worse {
      position: absolute;
      bottom: -75px;
      left: 0;
      width: 140px;
      line-height: 1.1em;
      font-size: 0.6em;
      text-align: center; }
    .vas-scale .bar:before {
      background: url(../images/vasScale.png);
      content: " ";
      position: absolute;
      top: -5px;
      left: 25px;
      height: 410px;
      width: 137px; }
    .vas-scale .bar .marker {
      position: absolute;
      background: #42BABD;
      top: 0;
      left: -35px;
      width: 150px;
      height: 2px;
      -webkit-transition-property: top;
      -webkit-transition-duration: 0.5s; }
      .vas-scale .bar .marker .value {
        position: absolute;
        cursor: pointer;
        left: -45px;
        top: -18px;
        background: #42BABD;
        color: #fff;
        font-size: 0.8em;
        width: 50px;
        padding: 5px 10px;
        text-align: center;
        border-radius: 5px; }
