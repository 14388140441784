.questionnaire {
  background: #fff; }
  .questionnaire .title {
    background: #ecf5f5;
    padding: 20px; }
    .questionnaire .title span {
      display: block;
      max-width: 1280px;
      margin: auto;
      color: #4ABDBF;
      font-size: 1.2em;
      font-weight: bold; }
  .questionnaire .intro {
    max-width: 1280px;
    margin: auto;
    padding: 0 20px;
    font-size: 1.4em;
    line-height: 1.5em;
    color: #8c8c8c;
    padding: 40px; }
    .questionnaire .intro ul {
      padding: 0;
      margin: 0; }
  .questionnaire .question-phq8-9 {
    padding: 30px 0;
    font-size: 1.2em;
    color: #646464;
    font-weight: bold; }
    .questionnaire .question-phq8-9.invalid .question-text {
      color: red; }
    .questionnaire .question-phq8-9 .options {
      text-align: center;
      margin-top: 30px; }
      .questionnaire .question-phq8-9 .options .option {
        background: #ECF5F5;
        display: inline-block;
        margin-right: 10px;
        line-height: 45px;
        text-align: center;
        border: 2px solid #ECF5F5;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
        color: #414141;
        font-size: 1em;
        padding: 0 20px; }
        .questionnaire .question-phq8-9 .options .option.selected {
          color: #42BABD;
          border: 2px solid #42BABD; }
  .questionnaire .questions {
    padding: 0 40px;
    max-width: 1280px;
    margin: 40px auto; }
    .questionnaire .questions .border {
      position: relative;
      border: 2px solid #f1f1f1;
      padding: 0 30px;
      border-radius: 5px;
      box-sizing: border-box; }
    .questionnaire .questions .scale {
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      color: #8c8c8c;
      font-size: 1.3em;
      text-align: center; }
      .questionnaire .questions .scale > span {
        margin-right: 15px; }
    .questionnaire .questions .question {
      padding: 30px 250px 30px 0;
      position: relative;
      font-size: 1.2em;
      font-weight: bold;
      color: #646464;
      border-bottom: 1px solid #f1f1f1; }
      .questionnaire .questions .question:last-child {
        border-bottom: none; }
      .questionnaire .questions .question.invalid .question-text {
        color: red; }
      .questionnaire .questions .question .options {
        position: absolute;
        right: 0;
        top: 17px; }
        .questionnaire .questions .question .options .option {
          background: #ECF5F5;
          display: inline-block;
          margin-left: 10px;
          height: 45px;
          width: 45px;
          line-height: 45px;
          text-align: center;
          border: 2px solid #ECF5F5;
          cursor: pointer;
          border-radius: 5px;
          font-weight: bold;
          color: #414141;
          font-size: 0.9em; }
          .questionnaire .questions .question .options .option.selected {
            color: #42BABD;
            border: 2px solid #42BABD; }
      .questionnaire .questions .question.vas .pick {
        position: relative;
        background: #48BABC;
        -webkit-box-shadow: 0px 2px 2px 0px #388E8F;
        -moz-box-shadow: 0px 2px 2px 0px #388E8F;
        box-shadow: 0px 2px 2px 0px #388E8F;
        display: inline-block;
        padding: 15px 90px 15px 30px;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        font-size: 0.8em; }
        .questionnaire .questions .question.vas .pick .value {
          display: block;
          position: absolute;
          right: 2px;
          top: 2px;
          padding: 13px 20px;
          background: #fff;
          color: #48BABC;
          border-radius: 0 5px 5px 0; }
      .questionnaire .questions .question.vas.invalid .pick {
        color: red; }
    .questionnaire .questions.vas {
      border: none;
      padding: 0;
      margin: 20px auto 0px auto; }
