#forum {
  margin: auto;
  min-height: 90%; }
  #forum .posts {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style: none; }
    #forum .posts li {
      position: relative;
      display: block;
      overflow: hidden; }
