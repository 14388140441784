.post-editor {
  position: relative;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.15); }
  .post-editor textarea {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 20px;
    border: none;
    outline: none;
    resize: vertical;
    overflow: hidden; }
  .post-editor img {
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: 50px;
    max-height: 50px; }
  .post-editor .options {
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    text-align: right; }
    .post-editor .options button {
      font-size: 15px;
      padding: 6px 14px;
      color: #fff;
      border: none;
      border-radius: 20px;
      outline: none;
      background-color: #3f9cad;
      cursor: pointer; }
      .post-editor .options button:disabled {
        background-color: #94cdd8;
        cursor: inherit; }
  .post-editor.has-parent {
    margin-left: 40px; }
